.chat {
  flex: 2;
  border-left: 1px solid #dddddd35;
  border-right: 1px solid #dddddd35;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd35;

      .user {
          display: flex;
          align-items: center;
          gap: 20px;

          img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              object-fit: cover;
          }

          .texts {
              display: flex;
              flex-direction: column;
              gap: 5px;

              span {
                  font-size: 18px;
                  font-weight: bold;
                  color: white; /* Changed text color for better visibility */
              }

              p {
                  font-size: 14px;
                  font-weight: 300;
                  color: #ddd; /* Lightened text for subtle contrast */
              }
          }
      }

      .icons {
          display: flex;
          gap: 20px;

          img {
              width: 20px;
              height: 20px;
          }
      }
  }

  .center {
      padding: 20px;
      flex: 1;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-end;
      .message {
          max-width: 70%;
          display: flex;
          gap: 20px;

          &.own {
              align-self: flex-end;

              .texts {
                  p {
                      background-color: #5183fe;
                      color: white; /* Ensure white text for user messages */
                  }
              }
          }

          img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
          }

          .texts {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;

              img {
                  width: 100%;
                  height: 300px;
                  border-radius: 10px;
                  object-fit: cover;
              }

              p {
                  padding: 20px;
                  background-color: rgba(17, 25, 40, 0.3);
                  border-radius: 10px;
                  color: white; /* Ensure white text in chat bubbles */
              }

              span {
                  font-size: 13px;
                  color: #ccc; /* Light text for timestamps */
              }
          }
      }
  }

  .bottom {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dddddd35;
      gap: 20px;
      margin-top: 0px;

      .icons {
          display: flex;
          gap: 20px;
      }

      img {
          width: 20px;
          height: 20px;
          cursor: pointer;
      }

      input {
          flex: 1;
          background-color: rgba(17, 25, 40, 0.5);
          border: none;
          outline: none;
          color: white; /* White text inside input box */
          padding: 20px;
          border-radius: 10px;
          font-size: 16px;

          &:disabled {
              cursor: not-allowed;
          }
      }

      .emoji {
          position: relative;

          .picker {
              position: absolute;
              bottom: 50px;
              left: 0;
          }
      }

      .sendButton {
          background-color: #5183fe;
          color: white; /* White text for send button */
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;

          &:disabled {
              background-color: #5182feb4;
              cursor: not-allowed;
          }
      }
  }
}
