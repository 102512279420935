/* DeleteRoom.css */
.delete-room-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 20px;
}

.delete-room-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 480px;
    overflow: hidden;
}

.delete-room-header {
    background-color: #4CAF50;
    padding: 20px;
    color: white;
}

.delete-room-header h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.delete-room-content {
    padding: 30px;
    text-align: center;
}

.warning-icon {
    width: 64px;
    height: 64px;
    margin: 0 auto 20px;
    color: #ff4444;
}

.warning-icon svg {
    width: 100%;
    height: 100%;
}

.delete-room-content p {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.delete-room-subtitle {
    margin-top: 10px !important;
    font-size: 14px !important;
    color: #666 !important;
}

.delete-room-actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f8f8f8;
    gap: 12px;
}

.btn-secondary, .btn-danger {
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
}

.btn-secondary {
    background-color: #f0f0f0;
    color: #333;
}

.btn-secondary:hover {
    background-color: #e0e0e0;
}

.btn-danger {
    background-color: #ff4444;
    color: white;
}

.btn-danger:hover {
    background-color: #ff3333;
}

@media (max-width: 480px) {
    .delete-room-card {
        margin: 10px;
    }
    
    .delete-room-actions {
        flex-direction: column-reverse;
    }
    
    .btn-secondary, .btn-danger {
        width: 100%;
    }
}