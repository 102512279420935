/* Main container styling */
.accountsettings {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5; /* Light background for contrast */
}

/* Header styling */
.accountsettings h2 {
    font-family: "Outfit", sans-serif;
    font-size: 2rem;
    font-weight: 500;
    color: #060b26; /* Consistent with navbar color */
    margin-bottom: 10px;
}

.accountsettings h5 {
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #555; /* Subtle grey for description */
    margin-bottom: 20px;
}

/* Form styling */
.accountsettings form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Label styling */
.accountsettings label {
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #333; /* Dark grey for label text */
    margin-bottom: 5px;
}

/* Input styling */
.accountsettings input {
    font-family: "Outfit", sans-serif;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.accountsettings input:focus {
    border-color: #060b26; /* Match navbar color */
}

/* Button styling */
.accountsettings button {
    background-color: rgb(56, 142, 60); /* Primary color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.accountsettings button:hover {
    background-color: #1a83ff; /* Lighter blue for hover */
}
