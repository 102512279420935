@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@500&display=swap');


.container{
    background-image: url('../images/usf-msc-wallpaper.jpg'); 
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.sign-up{
    height: 460px;
    background-color: white;
    border-radius: 5px;
    padding: 32px;
    width: 380px;
    font-family: 'Outfit', sans-serif;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

form{
    display: flex;
    flex-direction: column;
    gap: 9px;
    border-radius: 100%;
}
