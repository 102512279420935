.chat-list {
  height: 100vh;
  background-color: #ffffff;
  position: relative;
}

/* Messages List Styles */
.messages-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.messages-header {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.messages-header h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.messages-list {
  padding: 20px 0;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.chat-item:hover {
  background-color: #f8f9fa;
}

.chat-item.unread {
  background-color: #f0f4ff;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #5183fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.initials {
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: auto
}

.chat-content {
  flex: 1;
  min-width: 0;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.username {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.timestamp {
  font-size: 12px;
  color: #888;
}

.last-message {
  color: #666;
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Chat Container Styles */
.chat-container {
  height: 100vh;
  position: relative;
  background-color: #fff;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  z-index: 1000;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.header-title h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.back-arrow {
  font-size: 24px;
  color: #5183fe;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.header-title:hover .back-arrow {
  transform: translateX(-4px);
}

.chat-wrapper {
  height: calc(100vh - 80px);
  margin-top: 80px;
  overflow-y: auto;
  padding: 20px;
}