/* Navbar Base Styles */
.navbar-search {
  display: flex;
  flex-direction: row;
  width: 70%;  /* Increased from 40% to 60% */
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

/* Search Container */
.MuiInputBase-root {
  width: 100%;
  padding: 4px 8px;
}

/* Filter and Menu Styling */
.nav-menu {
  background-color: #ffffff;
  width: 300px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 24px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: -100%;
  right: 55%;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.nav-menu.active {
  top: 75px;
  transform: translateY(0);
}

.nav-menu-items{
  padding: 0px;
}

/* Filter Sections */
.filters-wrapper {
  width: 100%;
  padding: 8px;
}

.filter-section {
  margin-bottom: 24px;
  width: 100%;  /* Ensure full width */
}

/* Price Range Inputs */
.price-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 8px;
  width: 100%;  /* Ensure full width */
}

.filter-input {
  width: 100%;  /* Make inputs take full width of their container */
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
}

/* Date Range Inputs */
.date-inputs {
  display: grid;
  grid-template-columns: 1fr;  /* Stack date inputs vertically */
  gap: 12px;
  margin-top: 8px;
  width: 100%;
}

/* Profile Menu Styling */
.profile-menu {
  background-color: #ffffff;
  width: 240px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: fixed;
  top: -100%;
  right: 16px;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.profile-menu.active {
  top: 75px;
  transform: translateY(0);
}

.profile-menu-container {
  padding: 8px;
}

.profile-menu-button {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.profile-menu-button:hover {
  background-color: rgba(46, 125, 50, 0.08);
}

/* SearchBox Specific Styles */
.MuiInputBase-root.MuiInputBase-colorPrimary {
  width: 100%;
  background-color: white;
  border-radius: 4px;
}

.MuiInputBase-input {
  padding: 8px 12px !important;
}

/* Responsive Design */
@media (max-width: 800px) {

  .navbar-search {
    width: 90%;  /* Increased from 40% to 60% */  
    margin: 0;
  }

  .nav-menu {
    right: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
  }
  
  .profile-menu {
    right: 0;
    border-radius: 0;
  }

  .sublease-title {
    display: none;
  }
}