.usersidebar{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.usersidebar .s1, .usersidebar .s2{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.usersidebar svg{
    width: 30px;
    height: 30px;
    color: grey;
}

.usersidebar .s1:hover{
    background-color: rgb(246, 246, 246);
    border-radius: 5px;
    cursor: pointer;
    color: grey;
}


.usersidebar .s2{
    background-color: rgb(246, 246, 246);
    border-radius: 5px;
    cursor: pointer;
    color: grey;
}

.stylenone{
    text-decoration: none;
    color: inherit;
}