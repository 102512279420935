@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@500&display=swap');

.addroom-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addroom-content {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #F0F4F0;
}

.responsive-form {
  width: 80%;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px;
  position: relative; /* Add position relative */
}

.left-column {
  width: 40%;
  padding: 30px;
}

.right-column {
  width: 55%;
}

.form-input {
  width: 100%;
  padding: 8px 5px;
  border-radius: 6px;
  border: 1px solid #E2E8F0;
  font-size: 14px;
  transition: border-color 0.2s;
  outline: none;
}

.form-textarea{
  width: 100%;
  height: 70px;
  padding: 8px 5px;
  border-radius: 6px;
  border: 1px solid rgb(226, 232, 240);
  font-size: 14px;
  transition: border-color 0.2s;
  resize: vertical;

}

.submit-button-left{
  background-color: #38A169;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button-right{
  background-color: #38A169;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: none;
}


@media (max-width: 768px) {
  
  
  .responsive-form {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }

  form input, form textarea {
    width: 100%;
    font-size: 16px;
    margin-bottom: 15px;
  }

  /* Fix any potential overflow issues */
  body {
    overflow-x: hidden;
  }

  .left-column ,
  .right-column {
    width: 100%;
  }

  .submit-button-left{
    display: none;
  }

  .submit-button-right{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px
  }

}


/* CSS for Drag and Drop */
.card {
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}
  
.card.top{
  text-align: center;  
}
  
.card p {
  font-weight: bold;
  color: #0086fe;
}
  
.card button{
  outline: 0; 
  border: 0; 
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400; 
  padding: 8px 13px;
  width: 100%;
  background: #0086fe
}
  
.card .drag-area {
  height: 300px;
  border: "2px dashed #9AE6B4";
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: "#F0FFF4";
  transition: "all 0.3s ease";
  -webkit-user-select: none;
  margin-top: 10px;
  cursor: "pointer"
}
  
.card .drag-area .visible{
  font-size: 18px;
}
 

.card .select{
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.45;
}
  
.card .select:hover{
  opacity: 0.6;
}
  
.card .image-container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}
  
.card .image-container .image{
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom:8px;
}


.card .image-container .image img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card .image-container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.card input, 
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 999;
}