@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Outfit:wght@500&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto; /* Ensure the whole page can scroll */
}

.map-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure container takes the full viewport height */
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .save-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .save-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    stroke: currentColor;
  }
.first-section {
    display: flex;
    flex: 1; /* Take 70% of the viewport height */
    overflow: hidden; /* Hide overflowing content */
    gap: 20px; /* Space between map and info section */
    padding: 20px; /* Padding around the first section */
    box-sizing: border-box; /* Include padding in total width */
}

.map-section {
    flex: 2; /* Map section takes up less space */
    max-width: 60%; /* Map takes up 60% of the container */
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #e0e0e0; /* Background color for the map container */
    height: 100%; /* Take full height of the first section */
}

.map {
    width: 100%;
    height: 100%;
}

.info-section {
    flex: 3; /* Apartment information takes up more space */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Scroll if content exceeds height */
    box-sizing: border-box; /* Include padding in total width */
    height: calc(100% - 20px); /* Adjust height considering padding */
    max-height: 100%; /* Ensure section height doesn't exceed the container */
    font-family: 'Outfit', sans-serif; /* Apply custom font to info section */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Stack children vertically */
}

.info-section h2{
    font-family: 'Outfit', sans-serif; /* Apply custom font to info section */
}

.section-title {
    color: green;
    margin-bottom: 15px;
    font-size: 28px; /* Increase font size for section title */
    font-weight: 700; /* Bold text */
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    gap: 20px; /* Space between grid items */
    font-family: 'Montserrat', sans-serif; /* Default font */
    margin-bottom: 20px; /* Space between grid and carousel */
}

.info-grid p {
    font-size: 18px; /* Increase font size for paragraphs */
    line-height: 1.5; /* Improve readability */
    margin: 10px 0; /* Margin between paragraphs */
    font-family: 'Montserrat', sans-serif; /* Default font */
}

.info-grid p strong{
    font-size: 18px; /* Increase font size for paragraphs */
    line-height: 1.5; /* Improve readability */
    margin: 10px 0; /* Margin between paragraphs */
    font-family: 'Montserrat', sans-serif; /* Default font */
}

.carousel-container {
    position: relative; /* Ensure buttons are positioned relative to this container */
    width: 100%; /* Full width of the container */
    flex: 1; /* Allow the carousel to grow and use remaining space */
    margin-top: 20px; /* Space between info grid and carousel */
    overflow: hidden; /* Hide overflow to ensure images don't affect layout */
    min-height: 300px;
}


.carousel {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the image horizontally */
    height: 100%; /* Full height of the carousel container */
}

.carousel-button {
    background-color: #148d2e; /* Button color */
    color: white;
    border: none;
    padding: 12px 20px; /* Increase padding for larger buttons */
    font-size: 18px; /* Adjust font size for readability */
    cursor: pointer;
    border-radius: 8px; /* Rounded corners */
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
    z-index: 1; /* Ensure buttons are above the image */
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s; /* Smooth transitions */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.carousel-button:hover {
    background-color: #0056b3; /* Darker color on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.carousel-button:active {
    transform: translateY(-50%) scale(0.95); /* Slightly shrink button on click */
}

.carousel-button.prev {
    left: 10px; /* Position the 'previous' button */
}

.carousel-button.next {
    right: 10px; /* Position the 'next' button */
}

.carousel-image {
    max-width: 80%; /* Make the image slightly smaller */
    max-height: 100%; /* Restrict max height to fit container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover; /* Cover the space without distortion */
}


.carousel-thumbnails {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px; /* Space between the carousel and thumbnails */
}

.thumbnail {
    width: 80px; /* Width of thumbnail */
    height: 60px; /* Height of thumbnail */
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent; /* Border to indicate selection */
    transition: border-color 0.3s ease; /* Smooth border color transition */
}

.thumbnail.active {
    border-color: green; /* Highlight selected thumbnail */
}

.thumbnail:hover {
    opacity: 0.8; /* Slightly fade thumbnail on hover */
}


.message-box {
    width: 100%;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .message-header {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #65676b;
  }
  
  .sender-name {
    font-weight: bold;
    color: #000;
  }
  
  .timestamp {
    color: #888;
  }
  
  .message-content {
    margin-top: 5px;
  }
  
  .message-content p {
    margin: 0;
    background-color: #fff;
    border-radius: 18px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: #000;
    max-width: 80%;
  }
  
  .message-input-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .message-input {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 14px;
    border-radius: 20px;
    background-color: #f0f2f5;
  }
  
  .send-button {
    background-color: #0084ff;
    border: none;
    color: #fff;
    padding: 8px 12px;
    margin-left: 5px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .send-button:hover {
    background-color: #0074e4;
  }

/* Responsive Design */
@media (max-width: 768px) {
    .first-section {
        flex-direction: column;
    }

    .map-section {
        max-width: 100%; /* Ensure map takes full width on smaller screens */
        height: 300px; /* Adjust height for smaller screens */
    }

    .info-section {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .carousel-image {
        max-height: 50vh; /* Adjust max height for smaller screens */
    }

    .info-grid {
        grid-template-columns: 1fr; /* Single column layout on smaller screens */
    }
}


/* Responsive Design */
@media (max-width: 768px) {
    .first-section {
        flex-direction: column;
    }

    .map-section {
        max-width: 100%; /* Ensure map takes full width on smaller screens */
        height: 300px; /* Adjust height for smaller screens */
    }

    .info-section {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .apartment-image {
        max-height: 50vh; /* Adjust max height for smaller screens */
    }

    .info-grid {
        grid-template-columns: 1fr; /* Single column layout on smaller screens */
    }
}