.userprofile{
    display: flex;
    flex-direction: column;
}

.userprofilein{
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    gap: 20px;
}

.userprofile .userprofilein .left{
    width: 20%;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    min-height: 85vh;
    
}

.userprofile .userprofilein .right{
    width: 60%;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    min-height: 85vh;
    overflow: auto;
}

@media (max-width: 1080px) {
    .userprofilein{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .userprofile .userprofilein .left{
        width: fit-content;
        min-height: 6vh;
    }
    .userprofile .userprofilein .left .usersidebar {
        display: flex;
        flex-direction: row;    
    }

    .userprofile .userprofilein .right {
        width: 90%;
        height: min-content;
    }

    .accountsettings{
        width: fit-content;
    }
    
}