@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@500&display=swap');


.container{
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    animation: changeBackground 20s infinite ease-in-out;
}

@keyframes changeBackground {
    0% { background-image: url('../images/usf-msc-wallpaper.jpg'); }
    25% { background-image: url('../images/imgbs5.webp'); }
    50% { background-image: url('../images/imgbs3.webp'); }
    75% { background-image: url('../images/imgbs1.webp'); }
    90% { background-image: url('../images/imgbs10.jpg'); } /* Hold last image longer */
    100% { background-image: url('../images/usf-msc-wallpaper.jpg'); } /* Smooth transition to first */
  }
  
  

  .login {
    background-color: white;
    border-radius: 15px;
    padding: 30px;
    width: 450px;
    max-width: 90%;
    font-family: 'Outfit', sans-serif;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .MuiTextField-root {
    transition: all 0.3s ease;
  }
  
  
  
  .error-message {
    color: #f44336;
    text-align: center;
    margin-top: 10px;
  }
  
  @media (max-width: 600px) {
    .login {
      width: 100%;
      padding: 20px;
    }
  }